<template>
<a-row id="_chatbot" class="chatbot-box" style="height:0">
    <a-col :span="10">
        <img :src="chatbotImg" class="chatbot-logo"/>
    </a-col>
    <a-col :span="14" class="messages-box">
        <div class="chatbot-message">
            <div id="_messageList" class="chatbot-message-list">
                
            </div>
            <div class="chatbot-message-footer">
                <form v-on:submit.prevent="onSendMessage">
                <input id="_text" ref="content" autocomplete="off" class="chatbot-message-input" />
                <button class="chatbot-message-send">Send</button>
                </form>
            </div>
        </div>
    </a-col>
</a-row>
</template>

<script>
import jq from 'jquery'
import global from '../global'
export default {
    name: 'Chatbot',
    data() {
        return {
           chatbotImg: require('../assets/img/chatbot01.png'),
           messages:[]
        }
    },
    methods: {
        onSendMessage(e){
            var content = document.querySelector('#_text').value;
            if(content == '' || content == null){
                return false;
            }
            var language = 'en';
            if(this.$cookies.isKey('language')){
                language = this.$cookies.get('language');
            }
            var that = this;
            var spanNode = global.chatbot.elementContent("message-item me",content,true);
            document.querySelector('#_text').value = "";
            var ele = document.getElementById('_messageList');
                ele.scrollTop = ele.scrollHeight;
            jq.post('http://innocell.alliancetgl.com/sessionLog/ask.do',{
                content:content,
                idChaBot:global.chatbot.chatbot_id,
                languageCode:language,
                sessionId:this.$cookies.get('session_id')
            },function(ret){
                if(ret.code == 0){
                    if(spanNode != null){
                        spanNode.classList.remove("messsage-reload");
                    }
                    var contents = ret.data.content;
                    if(contents == '' || contents == null){
                        contents = "Sorry, I don't know what you said";
                        if(language == 'zh_HK'){
                            contents = '我正在學習變得更好，你有什麼意見給我'
                        }else if(language == 'zh_CN'){
                            contents = '我正在学习变得更好，你有什么意见给我'
                        }
                    }
                    global.chatbot.elementContent("message-item",contents,false);
                    var ele = document.getElementById('_messageList');
                        ele.scrollTop = ele.scrollHeight;
                }else{
                    if(spanNode != null){
                        spanNode.classList.add("messsage-warning");
                    }
                }
            },"json")
        },
        
    },
    mounted() {
        this.messages = [];
        
        this.$nextTick(function(){
            
        })
    }
}
</script>

<style lang="less">

</style>
