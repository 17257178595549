import Vue from 'vue'
import Vuex from 'vuex'
import router from '@/router'
Vue.use(Vuex)
Vue.use(router)
export default new Vuex.Store({
  state: {
  	goBackHome:{}
  },
  mutations: {
  	setGoBackHome(state,value){
  		if(value == 'zh_HK'){
  			location.href = "/zh-HK"
  		}else if(value == 'zh_CN'){
  			location.href = "/zh-CN"
  		}else{
  			location.href = "/"
  		}
  		
  		//Vue.$router.push({'/zh-HK'})
  	}
  },
  actions: {
  	
  },
  modules: {
  }
})
