<template>
<a-form :form="form" @submit="handleSubmit" class="p-form">
    <div class="dialog dialog-login" >
        <div class="dialog-body">
            <div class="" v-if="!isForm">
                <div>
                Are you ready to apply for InnoCell Accommodation?<br/>
                Please click on the group you belong to
                </div>
                <div style="margin: 35px 0 10px;text-align:center;">
                    <p>Existing Park Companies</p>
                    <p style="margin-top: 5px;"><a class="btn btn-primary" @click="loginForm">Login</a></p>
                    
                    <p style="margin-top: 20px;line-height: 150%;">HKSTP special program & Prospective companies or event participants</p>
                    <p style="margin-top: 5px;"><a class="btn btn-primary" @click="loginView">Login</a></p>
                    <!--<p class="m-notice">Don't have an account yet? <a href="javascript:;" @click="showRegisterView">Sign Up</a></p>-->
                </div>
                <br/>
                <!--
                <div style="margin: 35px 0 10px;">
                    <a class="btn btn-primary btn-block">Log in for Companies</a>
                    <p class="m-notice">Don't have an account yet? <a href="javascript:;" @click="$router.push({path: '/register'});" >Sign Up</a></p>
                </div>
                -->
            </div>
            <div class="form" v-if="isForm">
                <a-alert
                    v-if="isError"
                    :message="errorMessage"
                    type="error"
                    banner
                    closable
                  />
                <a-form-item label="Email">
                <a-input
                  class="form-control"
                  v-decorator="['email', { rules: [
                  {type: 'email',message:'The input is not valid E-mail!'},
                  { required: true, message: 'Please input your name!' }]
                   }]"
                />
              </a-form-item>
                <a-form-item label="Password" style="margin-bottom: 5px;">
                <a-input
                  class="form-control"
                  type="password"
                  v-decorator="['password', { rules: [{ required: true, message: 'Please input your password!' }] }]"
                />
              </a-form-item>
                <p><a href="javascript:;" @click="$router.push({path: '/findpassword'});" class="tinoe">forgot password?</a></p>
                
                <div style="margin:25px 0 0;">
                    <button :disabled="btnDisabled" class="btn btn-info btn-block" type="submit" >
                    <a-spin :indicator="indicator" v-if="btnLoad"/> 
                    Log in</button>
                </div>
                <p class="m-notice">Don't have an account yet? <a href="javascript:;" @click="showRegisterView">Next Step</a></p>
            </div>
        </div>
    </div> 
  <div v-if="isLoad">
    <a-spin />
  </div >
   <div class="manager-view" v-if="showviewHtml">
      <p>
        Existing Park Company under Special Programme/Project
        (Admission Guide and Form - <a href="https://innocell.hkstp.org/files/PartBExistingParkCompanyunderSpecialProgrammeProjectGuide.pdf" target="_blank">download here</a>)
        <br/>
        <br/>
      </p>
      <p>
        Prospective Company or Participant of Business Development Programme 
        (Admission Guide and Form - <a href="https://innocell.hkstp.org/files/PartCProspectiveCompanyorParticipantofBusinessDevelopmentProgrammeGuide.pdf" target="_blank">download here</a>)
        <br/><br/>
      </p>
      Please contact our HKSTP Account manager or email <a href="mailto:innocell.general@hkstp.com">innocell.general@hkstp.com</a> for further information.
      <br/>
      <p style="text-align:center;"><br/><br/><a class="btn btn-primary" @click="loginCloseView">OK</a></p>
  </div>
  <RegisterNotice v-if="signshowviewHtml"></RegisterNotice>
  </a-form>
</template>

<script>
import jq from 'jquery'
import global from '../global'
import RegisterNotice from '@/components/RegisterNotice'
export default {
    name: 'Dialog',
    components: {
        RegisterNotice,
    },
    data() {
        return {
            isForm:false,
            form: this.$form.createForm(this, { name: 'coordinated' }),
            isLoad:true,
            errorMessage:'',
            isError:false,
            makeHtml:'',
            showviewHtml:false,
            signshowviewHtml:false,
            indicator:<a-icon type="loading" style="font-size: 16px" spin />,
            btnLoad:false,
            btnDisabled:false,
        }
    },
    methods: {
        loginForm(){
            this.isForm = true;
        },
        handleSubmit(e){
          e.preventDefault();
          let that = this;
          that.isError = false;
          this.form.validateFields((err, values) => {
              if (!err) {
                that.btnDisabled = that.btnLoad = true;
                jq.post(global.api.domain+"forntend/login.do",values,function(response){
                    //that.isLoad = false;
                    that.btnDisabled = that.btnLoad = false;
                    if(response.code == 0){
                       that.$cookies.set('_USER_',response.data);
                       that.$cookies.set('_TOKEN_',response.data.token);
                       that.$message.success(
                          'Login successful',
                          1,
                        ).then(()=>{
                        that.$router.push({path: '/welcome'});
                        });

                    }else{
                       if(response.msg != null){
                            that.isError = true;
                            that.errorMessage = 'You already have an account, please go back to the login page';
                        }
                    }
                    console.log('Received values of form: ', response);
                })
                
            }
          });
        },
        loginView(){
              if(this.makeHtml == null || this.makeHtml == ''){
                this.makeHtml = document.createElement("div");
                this.makeHtml.classList.add("dialog-make");
                document.body.appendChild(this.makeHtml);
              }

              this.showviewHtml = true;
        },
        loginCloseView(){
            if(this.makeHtml != '' && this.makeHtml != null){
              this.makeHtml.remove();
              this.makeHtml = null;
            }
           
           this.showviewHtml = false;
        },
        showRegisterView(){
            if(this.makeHtml == null || this.makeHtml == ''){
              this.makeHtml = document.createElement("div");
              this.makeHtml.classList.add("dialog-make");
              this.makeHtml.id = "_makeHtml";
              document.body.appendChild(this.makeHtml);
            }
            this.signshowviewHtml = true;
        },

        
    },
    mounted() {
        

    }
}
</script>


