<template>
<div class="co-box">
    <img class="" :src="gitUnmind" alt="" id="mobile-coImg">
    <div id="CoCarousel" :style="coImgHeight" class="co-carousel wrapper">
        <div class="co-carousel-item" :style="screenWidth" id="carousel-01">
            <div class="co-img" :style="coImgStyle">
                <img class="" :src="coImg" alt="" id="_coImg">
                    <div class="co-carou-subtitle">
                    <h1 class="co-img-h1">
                        EXPERIENCE INNOVATIVE<br />
                        <span style="position:relative;"> SMART LIVING, <img class="co-img-squiggle" :src="gitGreenSquiggle" alt=""></span>
                        IN STYLE.
                    </h1>
                </div>
            </div>
            <div class="co-right-content co-background01">
                <div class="co-text co-content-view co-content-view-01" :style="contentView01">
                    <h1>CO-CREATE</h1>
                    <p>Get Inspired. Share Ideas. Co-Create.</p>
                    <br/>
                    <p>
                        Have a big idea that you want to get off the ground?  InnoCell is a place to make it happen.  At InnoCell, you'll be surrounded by a likeminded community of go-getters that will inspire you to get up and “get it” every day. <br/><br/>
                         We're redefining co-working and community living with a space that facilitates collaboration.  From a high-speed internet network throughout the building to a fully-furnished private place to sleep at night—we take care of everything, so that you have more time to spend on the important things.  Just bring your suitcase.  All that's missing is you.
                    </p>
                    <div class="text-right right-btn"><img :src="pngRight" class="click-btn" alt="" @click="next_info"></div>
                </div>
            </div>
            <div class="co-background co-background-01" :style="contentView01"></div>
        </div>
        <div class="co-carousel-item" :style="screenWidth" id="carousel-02">
            <div class="co-img" :style="coImgStyle">
                <img class="" :src="gitInspire" alt="" id="_coImg">
                <div class="co-carou-subtitle">
                    <h1 class="co-img-h1">
                        EXPERIENCE INNOVATIVE<br />
                        <span style="position:relative;"> SMART LIVING, <img class="co-img-squiggle" :src="gitGreenSquiggle" alt=""></span>
                        IN STYLE.
                    </h1>
                </div>
            </div>
            <div class="co-right-content co-background02">
                <div class="co-text co-content-view co-content-view-02" :style="contentView01">
                    <h1>CO-WORK</h1>
                    <p>Meet your next great co-collaborator.</p>
                    <br/>
                    <p>
                        Let's face it, working is so much more fun when you're doing it alongside brilliant people.  No one wants to spend all day every day working alone!  Within our co-working community, you will find your next great collaborators.  We are warm, we are welcoming and we are open-minded.  We push each other to do more, to be better and to persevere.  At InnoCell, no idea is too small, too silly or too outrageous.  The support of our community gives you the courage to be brave, take risks and push the boundaries of innovation and technology.
                    </p>
                    <div class="text-right right-btn"><img :src="pngRight" class="click-btn" alt="" @click="next_info"></div>
                </div>
            </div>
            <div class="co-background co-background-02" :style="contentView01"></div>
        </div>
        
        <div class="co-carousel-item" :style="screenWidth" id="carousel-03" >
            <div class="co-img" :style="coImgStyle">
                <img class="" :src="unmind_animation" alt="" id="_coImg">
                <div class="co-carou-subtitle">
                   <h1 class="co-img-h1">
                        EXPERIENCE INNOVATIVE<br />
                        <span style="position:relative;"> SMART LIVING, <img class="co-img-squiggle" :src="gitGreenSquiggle" alt=""></span>
                        IN STYLE.
                    </h1>
                    
                </div>
            </div>
             <div class="co-right-content co-background03">
                <div class="co-text co-content-view co-content-view-03" :style="contentView01">
                    <h1>CO-LIVE</h1>
                    <p>A space to call your own.</p>
                    <br/>
                    <p>
                        Need a private place to sing at the top of your lungs in the shower? We know how important it is to have personal space.  Our private living spaces offer you comfortable, well-considered rooms in which to recharge, sleep and enjoy 'me' time.  A private bathroom, an individual mini-kitchen and your own living area in each unit ensures that you have all your individual daily necessities covered.  In addition to your private room, vibrant community amenities and energetic green outdoor spaces offer you the environment to meet, work with and socialise with other tenants.  InnoCell is more than just a place to live — it's a place for you to thrive.
                    </p>
                    <div class="text-right right-btn"><img :src="pngRight" class="click-btn" alt="" @click="next_info"></div>
                </div>
            </div>
            <div class="co-background co-background-03" :style="contentView01"></div>
        </div>
    </div>
    <div class="co-carou-subtitle-mobile">
        <h1 class="co-img-h1">
            EXPERIENCE INNOVATIVE<br />
            SMART LIVING, IN STYLE.
        </h1>
    </div>
</div>

</template>

<script>
import jq from 'jquery'
import global from '../global'
export default {
    name: 'CoCarousel',
    data() {
        return {
            pngRight: require('../assets/img/right.png'),
            gitInnovate: require('../assets/img/gif/co/innovate_animation.gif'),
            gitInspire: require('../assets/img/gif/co/inspire_animation.gif'),
            gitUnmind: require('../assets/img/gif/co/20201119174424.gif'),
            unmind_animation: require('../assets/img/gif/co/unmind_animation.gif'),
            gitGreenSquiggle: require('../assets/img/gif/co/green_squiggle_animation.gif'),
            coImg: '',
            coImgStyle: {},
            coTextStyle: {
                right: 0,
                top: '100px',
                zIndex: 2,
            },
            coBackgroundStyle: {
                top: '50px',
                right: '50px',
                zIndex: 998,
                background: '#fc33b6'
            },
            coImgHeight:{
                height:'700px',
                width:'1505px;'
            },
            coIndex: 1,
            contentView01:{
                height:'700px',
            },
            screenWidth:{
                width:"1000px;"
            },
            clientWidth:0,
            timer: false,
            screenWidths:0,
            isLoad : false,
        }
    },
    methods: {
        next_info() {
            var that = this;
            this.coIndex++;
            if (this.coIndex > 3) this.coIndex = 1
            switch (this.coIndex) {
                case 1:
                    this.coImg = this.gitInnovate
                    var oImg = document.getElementById("_coImg")
                    oImg.onload = function(){
                         var domH = parseInt(oImg.height);
                         var itemHeight = jq(".co-carousel-item").height();
                         var h = domH+200
                         if(global.getScreenWidth() <= global.mobileWidth){
                            itemHeight = jq(".co-content-view-01").height();
                            h = itemHeight = parseInt(itemHeight) + 50;
                        }
                         
                         that.coImgHeight.height = h+"px";
                         jq("#carousel-01").animate({"left":"0px"},'normal')
                         jq("#carousel-03").animate({"left":(0-that.clientWidth)+"px"},'normal',function(){
                            jq("#carousel-03").css({"left":that.clientWidth+"px"})
                         })
                         
                    }
                    break
                case 2:
                    this.coImg = this.gitInspire
                    var oImg = document.getElementById("_coImg")
                    oImg.onload = function(){
                        var domH = parseInt(oImg.height);
                        var itemHeight = jq(".co-carousel-item").height();
                        var h = domH+200
                        if(global.getScreenWidth() <= global.mobileWidth){
                            itemHeight = jq(".co-content-view-02").height();
                            h = itemHeight = parseInt(itemHeight) + 50;
                        }
                        that.coImgHeight.height = h+"px";
                        jq("#carousel-02").animate({"left":"0px"},'normal')
                        jq("#carousel-01").animate({"left":(0-that.clientWidth)+"px"},'normal',function(){
                            jq("#carousel-01").css({"left":that.clientWidth+"px"})
                        })
                        
                    }
                    break
                case 3:
                    this.coImg = this.unmind_animation
                    var oImg = document.getElementById("_coImg");
                    oImg.onload = function(){
                        var domH = parseInt(oImg.height);
                        var itemHeight = jq(".co-carousel-item").height();
                        var h = domH+200
                        if(global.getScreenWidth() <= global.mobileWidth){
                            itemHeight = jq(".co-content-view-03").height();
                            h = itemHeight = parseInt(itemHeight) + 50;
                            console.log("3N"+itemHeight)
                        }
                        
                        that.coImgHeight.height = h+"px";
                        jq("#carousel-03").animate({"left":"0px"},'normal')
                        jq("#carousel-02").animate({"left":(0-that.clientWidth)+"px"},'normal',function(){
                            jq("#carousel-02").css({"left":that.clientWidth+"px"})
                        })
                        
                    }
                    
                    break
            }
        },
     
        _loadImage(){
            const that = this;
            that.clientWidth = global.getClientWidth();
            that.screenWidth = {
                width:this.clientWidth+"px"
            }
            console.log("===Y="+global.getClientWidth())
            var oImg = document.getElementById("_coImg")
            var domW = that.clientWidth;
            that.coImgHeight.width = domW+"px";
            if(that.isLoad){
                setTimeout(function(){
                    var domH = parseInt(oImg.height);
                    var itemHeight = 0;
                    var h = domH+200
                    if(global.getScreenWidth() <= global.mobileWidth){
                        var itemHeight = jq(".co-content-view-0"+that.coIndex).height()
                        h = itemHeight = parseInt(itemHeight) + 50;
                    }
                    
                    that.coImgHeight.height = h+"px";
                    that.coBackgroundStyle.height = domH+"px"
                    that.coTextStyle.height = domH+"px"
                    that.contentView01 = {
                        height:domH+"px",
                    }

                },500)
            }else{
                oImg.onload = function(){
                    var domH = parseInt(oImg.height);
                    var h = domH+200
                    if(global.getScreenWidth() <= global.mobileWidth){
                        var itemHeight = jq(".co-content-view-0"+that.coIndex).height()
                         h = itemHeight = parseInt(itemHeight) + 50;
                        console.log("N"+itemHeight)
                    }
                    
                    that.coImgHeight.height = h+"px";
                    that.coBackgroundStyle.height = domH+"px"
                    that.coTextStyle.height = domH+"px"
                    that.contentView01 = {
                        height:domH+"px",
                    }
                    that.isLoad = true;
                    
                }
            }
            jq("#carousel-01").css({"left":"0px"})
            jq("#carousel-02").css({"left":that.clientWidth+"px"})
            jq("#carousel-03").css({"left":that.clientWidth+"px"})
        },
    },
    mounted() {
        const that = this;
        this.coImg = this.gitInnovate
        
        this.$nextTick(function(){
            that._loadImage()
       })
        window.addEventListener("resize", function () {
            that.screenWidths = global.getScreenWidth();
        });
        window.addEventListener("orientationchange", function() {
            setTimeout(function(){
                that.screenWidths = global.getScreenWidth()
                that.clientWidth = global.getClientWidth();

                that.$nextTick(function(){
                     setTimeout(function(){that._loadImage()},1000)
                  }) 
                

            },100)
        }, false)
        
    },
    watch:{
        screenWidths:{
            immediate: true,
            handler: function(newVal) {
                if (!this.timer) {
                  const _this = this;    
                  _this.timer = true;
                  setTimeout(function(){
                      _this.clientWidth = global.getClientWidth();
                      _this._loadImage()
                      console.log("==="+global.getClientWidth())
                  },50)
                  setTimeout(() => {
                    _this.timer = false;
                  }, 100);
                }
            }
        }
    },
}
</script>

<style lang="less">

</style>
